<template>
  <div class="about">
    <vue-markdown :source="source"/>
  </div>
</template>
<script>
import VueMarkdown from 'vue-markdown'
import axios from 'axios'
import { mapActions } from 'vuex'

const baseUrl = 'https://assets.nl-kompetenzzentrum.de'

export default {
  name: 'textPage',
  components: {
    VueMarkdown
  },
  data () {
    return {
      source: ''
    }
  },
  created () {
    this.fetchContent()
    this.setStageVisible(false)
  },
  methods: {
    ...mapActions([
      'setStageVisible'
    ]),
    fetchContent () {
      axios.get(`${baseUrl}/content/${this.$route.name}/${this.$route.name}.md`)
        .then(response => {
          this.source = response.data
        })
    }
  }

}
</script>
<style lang="scss" scoped>
  .about {
    padding: 100px 2rem 2rem;
    margin-bottom: 2rem;
  }
</style>
